@import '../../assets/css/Fonts.scss';
@import '../../assets/css/partials/_App.scss';

.statusLight {
	$size: 8px;
	position: relative;
	padding-left: $size * 3;

	&::before {
		position: absolute;
		top: $size / 2;
		left: $size;
		content: ' ';
		width: $size;
		height: $size;
		border-radius: $size;
		background-color: $green;
	}

	&.inactive {
		&::before {
			background-color: $blue;
		}
	}

	&.processing {
		&::before {
			background-color: $orange;
		}
	}

	&.failed {
		&::before {
			background-color: $lipstick;
		}
	}
}


.wrapper {
	padding-top: 1.5em;
	overflow: hidden;
	@include mainContainer;

	&:after {
		display: block;
		content: '';
		width: 100%;
		height: 300px;
	}

	table {
		td {
			padding-left: 0;
		}
	}

	.label {
		margin-bottom: 1rem;
	}

	.sub {
		margin-bottom: 0;
	}

	.disabled {
		opacity: 0.5;
	}

	.link {
		text-decoration: none;

		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}

	.contactName {
		margin-bottom: 0;
	}

	.mobileNumber {
		margin-bottom: 2rem;
	}

	hr {
		margin-top: 1.5rem;
		margin-bottom: 1rem;
	}

	.contactEmail {
		color: $blue;
		text-decoration: underline;

		margin-bottom: 1rem;
		display: inline-block;
	}

	.links {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-gap: 1rem;
	}

	.crmEvents {
		margin-top: 0.5rem;

		& > div {
			margin-bottom: 1rem;
		}
	}

	.modalButton {
		margin-bottom: 1rem;
		gap: .5rem;

		text-transform: uppercase;

		padding: 1rem 1rem;
		border-radius: 5px;
		border: 1px solid $blue;
		text-decoration: none;
		cursor: pointer;
		color: $blue;

		display: flex;
		align-items: center;
		flex-direction: column;

		appearance: none;

		transition:
			color 0.15s ease-in-out,
			background-color 0.15s ease-in-out;

		&:hover {
			background-color: $blue;
			color: $white;

			text-decoration: none;
		}
	}
}
