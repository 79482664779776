@import '../../assets/css/partials/_App.scss';

.textFieldsWrapper {
  display: none;

  &.visible {
    display: block;
  }
}

.headingWrapper {
  @include clearfix;

  .leftSide,
  .rightSide {
    float: left;
  }

  .leftSide {
    width: 480px;
    margin-right: 30px;
  }

  .rightSide {
    width: 270px;
  }

  @include max-viewport('lg') {
    .leftSide {
      width: 400px;
    }

    .rightSide {
      width: 220px;
    }
  }

  @include max-viewport('md') {
    .leftSide,
    .rightSide {
      float: none;
      width: 100%;
    }
  }
}
