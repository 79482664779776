@import '../../assets/css/Fonts.scss';
@import '../../assets/css/partials/_App.scss';

.paginator {
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	button, span {
		margin: 0 5px;
		padding: 4px 8px;

		border-radius: .4rem;

		color: $grey;
		font-weight: normal;

		&.active {
			background-color: $blue;
			color: $white;
		}

		&:hover {
			cursor: pointer;
		}
	}
}
