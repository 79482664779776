@import '../../assets/css/partials/_App.scss';

.modal {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.2);
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 9999;

	& > div {
		@include mainContainer;

		@media screen and (min-width: 373px) {
			padding-left: 0;
			padding-right: 0;
		}

		@include min-viewport('md') {
			max-width: 450px;
		}

		@include min-viewport('lg') {
			max-width: 510px;
		}
	}

	.wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 100%;
		height: 80vh;
		max-height: 550px;
		padding-top: 30px;
		transform: translate(-50%, -50%);
		background-color: $white;
		border: solid 1px $lightGrey;
		border-radius: 5px;
		box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.1);
		overflow: hidden;

		.closeIcon {
			display: block;
			position: absolute;
			cursor: pointer;
			top: 5px;
			right: 10px;
			width: 26px;
			height: 26px;
		}

		.canvas {
			width: 100%;
			height: 100%;
			padding: 0 15px 15px 15px;
			overflow-y: auto;
			overflow-x: hidden;
			scroll-behavior: smooth;
		}
	}

	@include max-viewport('md') {
		border: 25px solid transparent;
	}

	@media screen and (max-width: 372px) {
		border: 20px solid transparent;
	}
}
