.productionWarningBanner {
	background-color: #fa1126;
	border: 1px solid #f5c6cb;
	color: #721c24;
	margin-bottom: 1rem;
	border-radius: 0.25rem;
	font-size: 1rem;
	line-height: 1.5;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 4rem;
	padding-bottom: unset !important;

	p {
		color: white;
		text-transform: uppercase;
		font-weight: bold;
	}
}
