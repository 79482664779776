@import '../../assets/css/partials/_App.scss';

.statusLight {
	$size: 8px;
	position: relative;
	padding-left: $size * 3;

	&::before {
		position: absolute;
		top: $size * 2;
		left: $size;
		content: ' ';
		width: $size;
		height: $size;
		border-radius: $size;
		background-color: $green;
	}

	&.inactive {
		&::before {
			background-color: $lipstick;
		}
	}
}

.nodeUrl {
	position: relative;
	font-size: 0.85rem;
	line-height: 1.3em;
	padding: 5px 10px 5px 20px;
	color: $nightLightBlue;
	flex-basis: 30%;

	img {
		position: absolute;
		top: 8px;
		left: 0;
		width: 14px;
		height: 14px;
	}
}

a.nodeName {
	display: inline-block;
	flex-basis: 100%;
}

.nodeUrlContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0 0 2rem;
	border-radius: 5px;
	cursor: pointer;
}

.linksContainer {
	border: 1px solid $nightLightBlue;
	border-radius: 5px;
	margin-bottom: 40px;
	overflow: hidden;

	& > div > div:not(:last-child) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding: 10px 0 0 10px;
	}

	& > div > div:last-of-type {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding: 0 0 0 10px;
	}

	hr:not(:last-child) {
		border: 0;
		border-top: 1px solid $nightLightBlue;
		margin: 0.9rem 0 0.9rem 0;
	}

	hr:last-child {
		margin: 0.9rem 0 0 0;
	}

	h4 {
		position: relative;
		font-size: 0.9rem;
		padding: 0.4em 10px;
		color: $white;
		margin-bottom: 0;
		background-color: $nightLightBlue;

		span {
			position: absolute;
			top: 0.35em;
			right: 10px;
		}
	}

	p {
		font-size: 0.8rem;
		padding: 0.4em 10px;
	}
}
