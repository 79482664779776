@import '../../assets/css/partials/_App.scss';

.toast {
	background-color: $black;
	border-radius: 4px;

	.body {
		color: $white;
	}

	.closeButton {
		color: $white;
	}

	.progressBar {
		background: $blue !important;

		&.warning {
			background: $orange !important;
		}

		&.error {
			background: $lipstick !important;
		}

		&.success {
			background: $lightGreen !important;
		}
	}
}
