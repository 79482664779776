@import '../../assets/css/partials/_App.scss';

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 50px;
	z-index: 1000;
	@include colouredBg;
	overflow: hidden;

	@media screen and (max-width: 480px) {
		height: 55px;
	}

	.menuIcon {
		float: right;
		color: $white;
		font-size: 40px;
		font-weight: 400;
		line-height: 0;
		margin-top: 3px;
		height: 20px;
	}

	&::after {
		content: ' ';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: rgba(0, 0, 0, 0.1);
	}

	.wrapper {
		width: 100%;
		height: 100%;
		padding-top: 7px;
		@include mainContainer;
		@media screen and (max-width: 373px) {
			padding-left: 10px;
			padding-right: 10px;
		}
		overflow: hidden;

		h1 {
			display: inline-block;

			.logo {
				width: 90px;

				@media screen and (max-width: 480px) {
					width: 80px;
				}
			}
		}

		.menu {
			float: right;
			margin-top: 4px;
		}
	}
}

:global {
	.rc-drawer {
		position: fixed;
		z-index: 1050;
		pointer-events: none;
		inset: 0;

		li {
			margin-bottom: 5px;
			padding: 5px 15px;
			border-radius: 4px;
			text-align: left;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				background-color: $blue;

				.link {
					color: $white;
				}
			}

			.link {
				display: inline-block;
				width: 100%;
				height: 100%;
				color: $nightBlue;
				font-size: 0.8rem;
				line-height: 2em;
				font-weight: 400;

				@media screen and (max-width: 480px) {
					font-size: 12px;
				}
			}
		}
	}
	.rc-drawer-inline {
		position: absolute;
	}
	.rc-drawer-mask {
		position: absolute;
		z-index: 1050;
		background: rgba(0, 0, 0, 0.5);
		pointer-events: auto;
		inset: 0;
	}
	.rc-drawer-content-wrapper {
		position: absolute;
		z-index: 1050;
		overflow: hidden;
		transition: transform 0.3s;
	}
	.rc-drawer-content-wrapper-hidden {
		display: none;
	}
	.rc-drawer-left .rc-drawer-content-wrapper {
		top: 0;
		bottom: 0;
		left: 0;
	}
	.rc-drawer-right .rc-drawer-content-wrapper {
		top: 0;
		right: 0;
		bottom: 0;
	}
	.rc-drawer-content {
		width: 100%;
		height: 100%;
		padding: 40px 10px 10px;
		overflow: auto;
		background: #fff;
		pointer-events: auto;
	}
}
