@import '../../assets/css/partials/_App.scss';

.footer {
  display: table-row;
  // margin-top: 20px;

  .wrapper {
    display: table-cell;
    border-top: 1px solid $lightGrey;
    width: 100%;
    text-align: center;
    // height: 100%;
    // padding-top: 20px;
    @include mainContainer;

    @media screen and (max-width: 374px) {
      padding-top: 20px;
    }

    button[role='link'] {
      display: block;
      color: $nightBlue;
      text-decoration: underline;
      font-size: 0.9rem;
      font-weight: 600;
      padding: 0 15px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    button {
      display: inline-block;
      margin: 1rem auto;
    }
  }
}
