@import '../../assets/css/partials/_App.scss';

.inputWrapper {
  display: table;
  position: relative;
  width: 100%;
  margin: 0 0 1rem 0;

  &.error {
    .input {
      border-color: $lipstick;
      color: $lipstick;
      padding-right: 4.5em;
    }

    .lblUp {
      & + .input {
        border-color: $lipstick;
      }
    }

    & .innerWrapper::after {
      display: inline-block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8em;
      content: '⚠';
      color: $lipstick;
      font-size: 1.3em;
      font-weight: 600;
    }

    &.currency {
      input {
        padding-right: 9.3em;
      }

      .innerWrapper::after {
        right: 4.7em;
      }
    }

    &.noTagging {
      .input {
        padding-right: 15px;
      }

      .innerWrapper::after {
        display: none;
      }
    }

    & > .innerWrapper ul {
      border-right-color: $lipstick !important;
    }

    & > .innerWrapper .currencyWrapper {
      border-left-color: $lipstick !important;
    }
  }

  & > .innerWrapper {
    position: relative;

    & > ul,
    & > select {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
    }

    & > select {
      opacity: 0;
    }
  }

  &.currency {
    input {
      padding-right: 90px;
    }

    .innerWrapper .currencyWrapper {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      font-size: 0.9rem;
      font-weight: 600;
      padding: 18px 15px;
      text-align: center;
      overflow: hidden;
      color: $nightBlue;
      border-left: 1px solid $lightGrey;
      transition: border-color 0.3s;

      img {
        float: left;
        width: 20px;
        margin: 2px 5px 0 0;
      }
    }
  }

  &.mobileNumber {
    input {
      padding-left: 90px;
    }

    .innerWrapper select {
      width: 80px;
      height: 100%;
      z-index: 101;
    }

    .innerWrapper ul {
      height: 100%;
      min-width: 45px;
      border-right: 1px solid $lightGrey;
      transition: border-color 0.3s;

      li {
        height: 100%;
        padding: 18px 15px;
        font-size: 0.9rem;
        font-weight: 600;
        text-align: center;
        overflow: hidden;

        img {
          float: left;
          width: 20px;
          margin: 2px 5px 0 0;
        }
      }
    }
  }

  &.focus {
    .innerWrapper ul {
      border-right-color: $orange;
    }

    .innerWrapper .currencyWrapper {
      border-left-color: $orange;
    }
  }
}

.input {
  display: table-row-group;
  width: 100%;
  background: transparent;
  padding: 14px 15px;
  outline: none;
  border: 0;
  border: 1px solid $lightGrey;
  color: $nightBlue;
  font-size: 1.1rem;
  border-radius: 4px;
  transition: border-color 0.3s, color 0.3s;
  -webkit-appearance: none;

  &:focus {
    border-color: $orange;

    @include placeholder {
      color: $dusk;
    }
  }

  &:disabled,
  &:read-only {
    border-color: $lightGrey;
  }

  @include placeholder {
    color: $nightBlue;
    transition: color 0.3s;
  }
}

.lbl {
  display: block;
  cursor: text;
  pointer-events: none;
  color: $nightBlue;
  font-size: 1.25rem;
  margin-bottom: 0.5em;
  transition: color 0.3s;

  &:empty {
    display: none;
  }
}

.lblUp {
  background-color: transparent;
}

.message {
  display: block;
  font-size: 0.9em;
  text-align: center;
  margin: 0;
  color: $lightGrey;
  min-height: 2em;

  &.error {
    color: $lipstick;
  }

  @include max-viewport('sm') {
    font-size: 0.8em;
    line-height: 11px;
  }

  &:empty {
    display: none;
  }
}
