@import '../../assets/css/partials/App';

.loader {
  opacity: 0;
  position: fixed;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.45) 100%
  ); /* FF3.6-15 */
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.45) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0.45) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#40000000', endColorstr='#73000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  top: -1px;
  left: -1px;
  height: 1px;
  width: 1px;
  transition: opacity 0.4s;
  overflow: hidden;
  z-index: 100000;

  &.visible {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &.active {
    opacity: 1;
  }

  & > div {
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    span {
      display: block;
      font-size: 0.9rem;
      margin-top: 0.8rem;
      color: $white;
    }

    svg {
      display: inline-block;
      width: 50px;
      height: 50px;

      circle {
        animation: rotate 2s linear infinite;
        stroke: $white;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg) translate(-50%, -50%);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
