@import '../../assets/css/partials/_App.scss';

.textFieldsWrapper {
	display: none;

	&.visible {
		display: block;
	}
}

.errorBox {
	border: 1px solid $lipstick;
	border-radius: 6px;
	padding: 15px;
	margin-top: 40px;
}

.accountExpiredLabel {
	padding: 8px;
	background-color: $lipstick;
	color: $white;
	font-weight: 700;
	font-size: 0.9rem;
}

.headingWrapper {
	@include clearfix;

	.leftSide,
	.rightSide {
		float: left;
	}

	.leftSide {
		width: 480px;
		margin-right: 30px;
	}

	.rightSide {
		width: 270px;
	}

	@include max-viewport('lg') {
		.leftSide {
			width: 400px;
		}

		.rightSide {
			width: 220px;
		}
	}

	@include max-viewport('md') {
		.leftSide,
		.rightSide {
			float: none;
			width: 100%;
		}
	}
}
