@import '../../assets/css/partials/_App.scss';

.statusLight {
	$size: 8px;
	position: relative;
	padding-left: $size * 3;

	&::before {
		position: absolute;
		top: $size * 2;
		left: $size;
		content: ' ';
		width: $size;
		height: $size;
		border-radius: $size;
		background-color: $green;
	}

	&.inactive {
		&::before {
			background-color: $lipstick;
		}
	}
}
