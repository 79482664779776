/* Colors */
$white: #fff;
$black: #000;
$nightBlue: #0b161d;
$blue: #05b0ff;
$nightLightBlue: #102128;
$navy: #0061f9;
$orange: #fb983a;
$lipstick: #d71f35;
$dusk: #55565a;
$indigo: #6366f1;
$grey: #bbb;
$ultraLightGrey: #f9fbff;
$lightGrey: #e9e9e9;
$green: #7dbc23;
$lightGreen: #baff00;

/* Mixins */
$breakpoints: (
	'xs': 320px,
	'sm': 575px,
	'md': 767px,
	'lg': 991px,
	'xl': 1199px
);

@function breakpoint($breakpoint-name) {
	$breakpoint-value: map-get($breakpoints, $breakpoint-name);
	@if $breakpoint-value {
		@return $breakpoint-value;
	}
	@warn "Breakpoint '#{$breakpoint-name}' not found in $breakpoints";
	@return map-get($breakpoints, 'md');
}

@mixin min-viewport($breakpoint-name) {
	$breakpoint-value: breakpoint($breakpoint-name) + 1;
	@if $breakpoint-value {
		@media screen and (min-width: $breakpoint-value) {
			@content;
		}
	}
}

@mixin max-viewport($breakpoint-name) {
	$breakpoint-value: breakpoint($breakpoint-name);
	@if $breakpoint-value {
		@media screen and (max-width: $breakpoint-value) {
			@content;
		}
	}
}

@mixin first($num) {
	@if $num == 1 {
		&:first-child {
			@content;
		}
	} @else {
		&:nth-child(-n + #{$num}) {
			@content;
		}
	}
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}
}

@mixin clearfix {
	&:after {
		content: '.';
		visibility: hidden;
		display: block;
		height: 0;
		clear: both;
	}
}

@mixin colouredBg {
	background: $nightBlue; /* Old browsers */
	background: -moz-linear-gradient(
		left,
		$nightBlue 0%,
		$nightLightBlue 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		left,
		$nightBlue 0%,
		$nightLightBlue 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(
		to right,
		$nightBlue 0%,
		$nightLightBlue 100%
	); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$nightBlue}', endColorstr='#{$nightLightBlue}', GradientType=1 ); /* IE6-9 */
	background-attachment: fixed;
}

@mixin mainContainer {
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;

	@media screen and (min-width: 373px) {
		padding-left: 25px;
		padding-right: 25px;
	}

	@include min-viewport('md') {
		padding-left: 0;
		padding-right: 0;
		max-width: 650px;
	}

	@include min-viewport('lg') {
		max-width: 780px;
	}
}
