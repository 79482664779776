@import '../../assets/css/partials/_App.scss';

.wrapper {
	margin: 0 auto;
	max-width: 1800px;
	padding: 0 10px;

	.csvButtonWrapper,
	.hideItemsButtonWrapper {
		display: flex;
		align-items: center;
		color: $blue;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.3rem;
		text-align: center;
		margin-bottom: 0.5rem;
		cursor: pointer;

		.button {
			display: inline-block;
			width: 28px;
			height: 28px;
			font-size: 16px;
			margin-right: 0.5rem;
			border: 1px solid $blue;
			border-radius: 4px;

			& > * {
				margin-top: 5px;
			}
		}
	}

	.hideItemsButtonWrapper {
		margin-left: 30px;
	}

	.toggleButton {
		display: inline-block;
		border-radius: 20px;
		background-color: $white;
		border: 1px solid $grey;
		font-weight: 400;
		font-size: 14px;
		padding: 6px 12px;
		margin-right: 10px;
		margin-bottom: 15px;

		&:hover {
			border-color: $blue;
		}

		&.active {
			background-color: $blue;
			border-color: transparent;
			color: $white;
		}
	}

	.dateRangeInputContainer {
		.datePreset {
			visibility: hidden;
			font-weight: 400;
			padding: 1px 3px;
			border-radius: 2px;
			border: 1px solid $grey;
			margin-right: 3px;
			margin-top: 10px;
			line-height: 1.3em;

			&:hover {
				background-color: $blue;
				border-color: $blue;
				color: $white;
			}
		}

		&:hover {
			.datePreset {
				visibility: visible;
			}
		}
	}

	& > h3 {
		position: relative;
		margin-bottom: 35px;
		padding-right: 45px;
	}

	.closeIcon {
		display: block;
		position: absolute;
		cursor: pointer;
		top: 5px;
		right: 0;
		width: 26px;
		height: 26px;
	}

	.adControl,
	.campaignControl {
		display: block;
		font-size: 20px;
		margin: 0 auto;
		color: $green;

		&.blocked {
			color: $navy;
		}

		&.active {
			color: $lipstick;
		}
	}

	.campaignControl {
		color: $indigo;
		font-size: 18px;
	}

	.settingsContainer {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-bottom: 10px;

		@include min-viewport('md') {
			flex-direction: row;
		}

		& > div {
			flex-basis: 20%;
			padding-left: 20px;
		}

		& > div.singleButton {
			flex-basis: 60px;

			.singleButtonWrapper {
				margin-top: 1.5rem;
				color: $blue;
				width: 37px;
				height: 37px;
				border: 1px solid $blue;
				border-radius: 4px;
				font-size: 21px;
				text-align: center;
				cursor: pointer;

				&:hover,
				&.active {
					background-color: $blue;
					color: $white;
				}

				& > * {
					margin-top: 7px;
				}
			}
		}
	}
}

.chartWrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 40px;

	& > * {
		text-align: center;
		flex-basis: 50%;
	}
}

.tableWrapper {
	margin-top: 20px;

	&.filtered table {
		border-top: 2px solid $orange !important;
	}
}

:global {
	.react-datepicker-popper {
		z-index: 1000 !important;
	}

	.ka-table {
		border-collapse: collapse;
		border: 1px solid $lightGrey !important;

		tbody tr:nth-child(even) {
			background-color: #fdfdfd;
		}
	}

	.ka-row {
		&:hover {
			background-color: #f9fafb !important;
		}
	}

	.ka-thead-cell {
		border-collapse: collapse;
		padding: 8px 10px;

		span {
			color: $dusk;
		}
	}

	.ka-thead-background {
		background-color: $white !important;
		cursor: default;
		font-size: 12px;
		color: $black !important;

		&:hover {
			color: $blue !important;
		}
	}

	.ka-tree-cell {
		line-height: 1.2em !important;
		border: 0 !important;
		background-color: $white;
		margin-bottom: 0;
		border-collapse: collapse;
		font-size: 12px;
		min-height: 67px;
		cursor: default;

		& > .ka-tree-empty-space + .ka-tree-empty-space + .ka-cell-text,
		& > .ka-tree-empty-space + .ka-icon + .ka-cell-text {
			width: 245px;
		}

		&
			> .ka-tree-empty-space
			+ .ka-tree-empty-space
			+ .ka-tree-empty-space
			+ .ka-cell-text {
			width: 220px;
			text-align: right;
		}
	}

	.ka-cell,
	.ka-summary-cell {
		font-size: 12px;
		border-collapse: collapse;
		border: 1px solid $lightGrey;
		padding: 8px 10px !important;

		.thumbnail {
			display: block;
			width: 50px;
			height: 50px;
		}
	}

	.ka-summary-cell {
		background: $white;
	}

	.ka-row {
		border-top: none !important;
		border-bottom: none !important;
	}
}
