@import './_App.scss';

label {
	display: block;
	font-weight: 400 !important;

	p {
		margin-bottom: 0.4rem;
		font-size: 0.9rem;
	}
}

:global {
	.filepond--panel-root {
		background-color: $ultraLightGrey !important;
		border: 1px solid $lightGrey;
		border-radius: 3px !important;
	}

	.select {
		border: none !important;

		.select__control {
			border: 1px solid $lightGrey;
			background-color: $ultraLightGrey;

			&.select__control--is-focused,
			&.select__control--menu-is-open,
			&:hover {
				box-shadow: none;
				border-color: $blue !important;
			}
		}

		.select__multi-value {
			background-color: $blue;
		}

		.select__multi-value__label {
			color: $white;
		}

		.select__multi-value__remove {
			color: $nightBlue;

			&:hover {
				background-color: $lipstick;
				color: $white;
			}
		}

		.select__option {
			&:hover {
				background-color: $ultraLightGrey;
			}

			&.select__option--is-selected {
				background-color: $blue;
			}
		}
	}
}

@mixin input-styles {
	font-size: 17px;
	border: 1px solid $lightGrey;
	background-color: $ultraLightGrey;
	border-radius: 4px;
	padding: 0.383rem 0.5rem;
	outline: none;
	width: 100%;
	transition: border 0.5s;

	&:focus,
	&:hover {
		border-color: $blue;
	}

	&[disabled] {
		background-color: transparent;

		&:focus,
		&:hover {
			border-color: $ultraLightGrey !important;
		}
	}
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
textarea {
	@include input-styles();
}

input[type='checkbox'] {
	position: absolute;
	opacity: 0;
}

input[type='checkbox'] + span {
	position: relative;
}

input[type='checkbox'] + span:before {
	content: '';
	margin-right: 10px;
	display: inline-block;
	vertical-align: text-top;
	width: 40px;
	height: 30px;
	background-color: $ultraLightGrey;
	border: 1px solid $lightGrey;
	border-radius: 3px;
	transition: border 0.5s;
}

input[type='checkbox']:hover + span:before {
	border-color: $blue;
}

input[type='checkbox']:checked + span:before {
	border-color: $blue;
	background: $blue;
}

input[type='checkbox']:checked + span:after {
	content: '';
	position: absolute;
	left: 14px;
	top: 13px;
	background: $white;
	width: 2px;
	height: 2px;
	box-shadow: 2px 0 0 $white, 4px 0 0 $white, 4px -2px 0 $white,
		4px -4px 0 $white, 4px -6px 0 $white, 4px -8px 0 $white;
	transform: rotate(45deg);
}

:global {
	.react-tel-input .form-control {
		@include input-styles();
		font-size: 1.1rem !important;
		border: 1px solid $lightGrey !important;
		background-color: $ultraLightGrey !important;
		border-radius: 3px !important;
		padding-left: 48px !important;
		height: auto !important;
		width: 100% !important;

		& + div {
			transition: border 0.5s;
			border: 1px solid $lightGrey !important;
			border-right: none;
		}

		&:focus,
		&:hover {
			border-color: $blue !important;
		}

		&:focus + div,
		&:hover + div {
			border-color: $blue !important;
		}
	}
}

textarea {
	min-height: 130px;
}

.primaryButton {
	display: inline-block;
	position: relative;
	background-color: $blue;
	color: $white;
	border-radius: 1.5rem;
	padding: 0.7rem 1.5rem;
	font-size: 1rem;
	font-weight: 400;
	text-transform: uppercase;
	transition: background-color 0.2s;
	min-width: 150px;
}

.smallButton {
	display: inline-block;
	position: relative;
	background-color: $white;
	border: 2px solid $nightBlue;
	color: $nightBlue;
	border-radius: 1.5rem;
	padding: 0.3rem 0.7rem;
	font-size: 0.8rem;
	font-weight: 700;
	transition: border-color 0.2s, color 0.2s;
	min-width: 110px;

	&.active,
	&:hover {
		color: $blue;
		border-color: $blue;
	}
	&.loading {
		border-color: $nightBlue;
		background-color: $nightBlue;
	}
}

.miniButton {
	display: inline-block;
	position: relative;
	background-color: $white;
	color: $nightBlue;
	border-radius: 1.5rem;
	padding: 0.25em 0.45em;
	font-size: 0.7rem;
	font-weight: 700;
	transition: border-color 0.2s, color 0.2s;
	min-width: 40px;

	&.loading {
		border-color: $nightBlue;
		background-color: $nightBlue;
	}
}

.inputWrapper {
	max-width: 480px;
	margin-bottom: 1.5rem;

	label {
		position: relative;
	}

	&.error {
		label p {
			color: $lipstick;
		}

		input,
		textarea {
			border-color: $lipstick !important;
		}

		label > p + div > div {
			border-color: $lipstick !important;
		}

		:global {
			.filepond--panel-root {
				border: 1px solid $lipstick;
			}
		}
	}

	@include max-viewport('lg') {
		& {
			max-width: none;
		}
	}
}
