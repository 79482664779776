@import '../../assets/css/partials/App';

$lineBarWidth: 45px;

.wrapper textarea,
.numbers .lineNo {
  font-size: 0.9rem;
  font-family: monospace !important;
  line-height: 1.2rem !important;
}

.wrapper {
  margin: 0;
  width: 100%;

  .lines {
    margin: 0;
    padding: 0;
    width: $lineBarWidth;
    float: left;
  }

  .numbers {
    border-top: 1px solid transparent;
    margin: 0;

    .lineNo {
      display: block;
      padding: 0 0.5em 0 0;
      margin: 0;
      color: $grey;
      text-align: right;
      white-space: nowrap;
    }
  }
}

.textarea {
  overflow: auto;
  width: calc(100% - #{$lineBarWidth});
  outline: none;
  box-shadow: none;
  resize: none;
  overflow: hidden;
  overflow-x: auto;
  overflow-wrap: break-word;
  padding-top: 0;
  padding-bottom: 0;
}

.lines .numbers .highlighted {
  position: relative;
  color: $white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  &:after {
    position: absolute;
    top: 0;
    right: -5px;
    content: ' ';
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 5px solid;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }

  &.error {
    background-color: $lipstick;
    &:after {
      border-left-color: $lipstick;
    }
  }

  &.warning {
    background-color: $orange;
    &:after {
      border-left-color: $orange;
    }
  }
}
