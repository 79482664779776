@import './Fonts.scss';
@import './partials/_App.scss';

/* Minimal CSS reset */
html {
	box-sizing: border-box;
	font-size: 17px;

	@media screen and (max-width: 374px) {
		font-size: 16px;
	}
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
	margin: 0;
	padding: 0;
	font-weight: normal;
}

select {
	font-size: 1rem;
}

ol,
ul {
	list-style: none;
}

img {
	max-width: 100%;
	height: auto;
}

/* General rules */
html,
body {
	height: 100%;
}

@mixin global-styles {
	font-family: 'Open Sans';
	font-weight: 400;
}

body,
input,
textarea {
	@include global-styles();
}

:global {
	.react-tel-input .form-control {
		@include global-styles();
	}
}

body {
	font-weight: 300;
	padding-top: 50px;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	color: $nightBlue;
	height: 100%;

	@media screen and (max-width: 374px) {
		padding-top: 65px;
	}
}

strong {
	font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
button {
	&,
	& strong {
		font-family: 'Open Sans';
		font-weight: 700;
		line-height: 1.1em;
		letter-spacing: 0.02em;
	}
}

h3 {
	color: $nightLightBlue;
	font-size: 1.5rem;
	line-height: 1.3em;
	margin-bottom: 1.9rem;
	overflow: hidden;

	span {
		float: right;
	}
}

h4 {
	color: $nightLightBlue;
	font-size: 1.1rem;
	line-height: 1.3em;
	margin-bottom: 1.1rem;
}

h5 {
	margin-bottom: 1rem;
	text-transform: uppercase;
}

hr {
	border: none;
	border-top: 1px solid $grey;
	margin: 0 0 35px;
}

fieldset {
	padding: 15px;
	border: 1px solid $grey;
	border-radius: 5px;
	margin-bottom: 20px;
}

a,
a:hover,
a:focus,
a:active {
	text-decoration: none;
}

.link {
	color: $nightBlue;

	&:hover {
		text-decoration: underline;
	}
}

label {
	font-family: 'Open Sans';
	font-weight: 700;
	line-height: 1.1em;
	letter-spacing: 0.02em;
}

body > div {
	& {
		height: 100%;
	}
	& > div:first-child {
		padding-bottom: 80px;
	}
}

table {
	width: 100%;
	border: none;
	color: $nightBlue;

	thead {
		border-bottom: none;

		tr:nth-child(odd) {
			font-weight: 700;
		}

		td {
			border-bottom-color: $lightGrey;
		}
	}

	tbody, thead {
		&.smallFont {
			font-size: 0.85rem;
		}

		td {
			padding: 10px 15px;
			border-bottom: 1px solid transparent;

			&.noBreak {
				white-space: nowrap;
			}

			&.overflow {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		tr:nth-child(even) {
			background-color: $ultraLightGrey;
		}
	}
}

button {
	outline: none;
	background-color: transparent;
	border: none;
	padding: 0;
	cursor: pointer;
}

.verticalCentering {
	display: table;
	height: 100%;

	& > * {
		display: table-cell;
		vertical-align: middle;
	}
}

.headingText {
	font-size: 1.1rem !important;
	margin-bottom: 2rem;
}

p {
	color: $dusk;
}

.bigTextLink {
	display: inline-block;
	font-family: 'Open Sans';
	font-weight: 700;
	font-size: 0.9rem;
	color: $dusk;
	text-decoration: none;

	&.dark {
		font-weight: 700;
		color: $nightBlue;
	}

	&::after {
		display: inline-block;
		content: ' ';
		border: solid $nightLightBlue;
		border-width: 0 2px 2px 0;
		padding: 0.15em;
		transform: rotate(-45deg);
		margin-left: 0.25em;
		margin-bottom: 0.06em;
	}
}

.container {
	width: 100%;
	height: 100%;

	.wrapper {
		padding-top: 1.5em;
		overflow: hidden;
		@include mainContainer;

		&:after {
			display: block;
			content: '';
			width: 100%;
			height: 300px;
		}
	}
}

.tab {
	display: inline-block;
	border-bottom: 2px solid transparent;
	padding: 0.5em 0;
	margin-right: 1.5em;
	color: $nightBlue;
	font-weight: 400;
	font-size: 16px;

	&.active {
		font-weight: 700;
		border-bottom-color: $blue;
	}
}

.error {
	color: $lipstick;
}
