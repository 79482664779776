@import '../../assets/css/partials/_App.scss';

.button {
	&[disabled] {
		background-color: $grey;
		cursor: default;
	}

	svg {
		display: inline-block;
		animation: rotate 2s linear infinite;
		width: 1em;
		height: 1em;

		circle {
			stroke: $white;
			stroke-linecap: round;
			animation: dash 1.5s ease-in-out infinite;
		}
	}
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}
