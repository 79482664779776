@import '../../assets/css/partials/_App.scss';

.textFieldsWrapper {
  display: none;

  &.visible {
    display: block;
  }
}

.headingWrapper {
  @include clearfix;

  .leftSide,
  .rightSide {
    float: left;
  }

  .leftSide {
    width: 480px;
    margin-right: 30px;
  }

  .rightSide {
    width: 270px;
  }

  @include max-viewport('lg') {
    .leftSide {
      width: 400px;
    }

    .rightSide {
      width: 220px;
    }
  }

  @include max-viewport('md') {
    .leftSide,
    .rightSide {
      float: none;
      width: 100%;
    }
  }
}

.currencyWarning {
	max-width: 430px;
	margin-bottom: 20px;
	margin-left: 15px;
	padding: 10px 23px;
	border: 1px $lightGrey solid;
  border-radius: 3px !important;
	background-color: $ultraLightGrey;
	color: $dusk;

	h5 {
		text-transform: initial;
		margin-bottom: 7px;
	}

	ul {
		list-style: inside;
		font-size: 0.75em;
	}

	p {
		color: $dusk;
    font-size: 0.75em;
    margin-top: 7px;
		font-weight: 700;
	}
}
