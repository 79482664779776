@import '../../assets/css/partials/_App.scss';

.fieldGroup {
	border-left: 2px solid $lightGrey;
	padding: 10px 25px;
	margin-bottom: 80px;
}

.mandatory {
	color: $lipstick;
}

.info {
	color: $navy;
}

.correct {
	color: $green;
	text-shadow: 1px 0 1px solid $grey;
}

.phoneNumber {
	display: inline-block;
	line-height: 12px;
	padding: 1px 3px;
	background-color: $lightGrey;
	letter-spacing: 0.5px;
	border-radius: 2px;
}

.tab {
	border-bottom: 2px solid transparent;
	padding: 0.5em 0;
	margin-right: 1.5em;
	color: $nightBlue;
	font-weight: 400;
	font-size: 16px;

	&.active {
		font-weight: 700;
		border-bottom-color: $blue;
	}
}

.option {
	position: relative;
	margin-right: 0.5em;
	padding: 0.6em 1.2em;
	border-radius: 2em;

	&.active {
		color: $white;
		background-color: $nightBlue;
	}
}

p.error {
	color: $lipstick;
	font-size: 0.9rem;
	font-weight: 700;
	margin-bottom: 20px;
}

p.warningBox {
	border: 1px solid $orange;
	padding: 10px;
	font-size: 15px;
	margin-bottom: 30px;
	border-radius: 5px;
}

p.infoBox {
	border: 1px solid $blue;
	padding: 10px;
	font-size: 15px;
	margin-bottom: 30px;
	border-radius: 5px;
}

.productContainer {
	margin-bottom: 60px;

	h4 {
		margin-bottom: 0.7em;
	}
}

.selectedSource {
	font-family: 'Courier New', Courier, monospace;
	font-weight: 700;
	color: $nightBlue;
	margin-bottom: 25px;
}

.shortLink {
	display: inline-block;
	font-family: 'Courier New', Courier, monospace;
	font-weight: 700;
	color: $white;
	background-color: $blue;
	border-radius: 5px;
	padding: 10px 15px;
	font-size: 17px;
	margin-bottom: 7px;
}

.funnelContainer {
	border: 1px solid $nightLightBlue;
	border-radius: 5px;
	margin-bottom: 40px;
	overflow: hidden;

	& > div {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	h4 {
		position: relative;
		font-size: 0.9rem;
		padding: 0.4em 10px;
		color: $white;
		margin-bottom: 0;
		background-color: $nightLightBlue;

		span {
			position: absolute;
			top: 0.35em;
			right: 10px;
		}
	}

	p.noData {
		padding: 0.4em 10px;
		font-size: 0.9rem;
	}

	.nodeContainer {
		display: flex;
		border-bottom: 1px solid $nightBlue;
		padding: 10px;
		cursor: default;

		&.tile {
			flex-basis: 20%;
			flex-grow: 1;
			border-bottom: none;

			@include max-viewport('lg') {
				flex-basis: 25%;
			}

			@include max-viewport('md') {
				flex-basis: 0% + calc(100 / 3);
			}

			@include max-viewport('sm') {
				flex-basis: 50%;
			}
		}

		&:hover {
			background-color: $ultraLightGrey;
		}

		.nodeName {
			position: relative;
			font-size: 0.85rem;
			line-height: 1.3em;
			padding: 5px 10px 5px 20px;
			color: $nightLightBlue;
			flex-basis: 30%;

			img {
				position: absolute;
				top: 8px;
				left: 0;
				width: 14px;
				height: 14px;
			}
		}

		a.nodeName {
			display: inline-block;
			flex-basis: 100%;
		}

		.sourceDataContainer {
			position: relative;
			display: inline-block;
			padding: 5px 10px;
			border-radius: 5px;
			cursor: pointer;

			&:hover {
				background-color: $blue;

				&.group {
					background-color: $orange;
				}

				.source {
					color: $white !important;
				}

				.account {
					color: $ultraLightGrey;
				}

				.icon {
					display: block;
				}
			}

			.icon {
				display: none;
				position: absolute;
				top: 2px;
				width: 24px;
				height: 24px;

				&.removeIcon {
					right: -23px;
				}
			}

			.source {
				font-family: 'Courier New', Courier, monospace;
				font-weight: 700;
				color: $nightBlue;
			}

			&.group .source {
				color: $orange;
			}

			.account {
				font-size: 0.75rem;
				margin-top: 3px;
				margin-bottom: 3px;
				color: $dusk;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&:last-child {
			border-bottom: none;
		}

		& > div {
			flex: 1;
			margin-right: 25px;
		}
	}
}
